
import ControllerPostList from 'Controllers/ControllerPostList'
import DateFormatter from "~/mixins/DateFormatter";

export default {
  components: {
    ControllerPostList,
  },
  mixins: [DateFormatter],
}
