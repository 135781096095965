
import ControllerCookie from 'Controllers/ControllerCookie'

export default {
  components: {
    ControllerCookie,
  },
  data() {
    return {
      isMounted: false,
    }
  },
}
