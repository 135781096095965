
import ControllerHeader from 'Controllers/ControllerHeader'
import EditorLink from 'Editors/EditorLink'
import clickOutside from 'v-click-outside'
import ControllerSearch from 'Controllers/ControllerSearch'
// import transitionExpand from '@/components/transition-expand'

export default {
  components: {
    ControllerHeader,
    EditorLink,
    ControllerSearch,
  },
  directives: {
    clickOutside: clickOutside.directive,
  },
  data() {
    return {
      showSearch: false,
      mobileActive: false,
    }
  },
  watch: {
    showSearch(v) {
      if (v) {
        setTimeout(() => {
          this.$nextTick(() => this.$refs.searchInput.focus())
        }, 200)
        setTimeout(() => {
          this.$nextTick(() => this.$refs.searchInputMobile.focus())
        }, 200)
      }
    },
    $route: {
      handler() {
        this.closeElements()
      },
      deep: true,
    },
  },
  methods: {
    closeElements() {
      this.showSearch = false
      if (this.mobileActive) {
        this.mobileActive = false
      }
    },
  },
}
