import { render, staticRenderFns } from "./PostList.vue?vue&type=template&id=4baff426&scoped=true"
import script from "./PostList.vue?vue&type=script&lang=js"
export * from "./PostList.vue?vue&type=script&lang=js"
import style0 from "./PostList.vue?vue&type=style&index=0&id=4baff426&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4baff426",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default})
