
import EditorImage from 'Editors/EditorImage'
import ControllerFooter from 'Controllers/ControllerFooter'
import EditorTipTap from 'Editors/EditorTipTap'
import EditorList from 'Editors/EditorList'
import EditorLink from 'Editors/EditorLink'

export default {
  components: {
    ControllerFooter,
    EditorTipTap,
    EditorList,
    EditorLink,
    EditorImage,
  },
  data() {
    return {
      inView: false,
      options: [
        {
          name: 'logo',
          label: 'Logo',
          type: 'image',
          size: '400x400',
        },
        {
          name: 'link',
          label: 'Poveznica',
          type: 'link',
        },
      ],
    }
  },
}
