
import ControllerError from 'Controllers/ControllerError'

export default {
  components: {
    ControllerError,
  },
  mounted() {
    if (document) {
      document.querySelector('.footer-wrapper').classList.add('hidden')
      document.querySelectorAll('.header-wrapper').forEach(el => {
        el.classList.add('hidden')
      })
    }
  },
  beforeDestroy() {
    if (document) {
      document.querySelector('.footer-wrapper').classList.remove('hidden')
      document.querySelectorAll('.header-wrapper').forEach(el => {
        el.classList.remove('hidden')
      })
    }
  }
}
