
import DateFormatter from "@/mixins/DateFormatter";

export default {
  mixins: [DateFormatter],
  data() {
    return {
      baseUrl : null,
      postTitle: null,
      ogImage: null,
      pageSlug: null,
    }
  },
  head() {
    const obj = {
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.postTitle
        },
        {
          hid: 'og:type',
          property: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:image',
          property: 'og:image',
          name: 'og:image',
          content: this.ogImage
        },
        {
          hid: 'og:url',
          property: 'og:url',
          name: 'og:url',
          content: this.$i18n.locale !== 'en' ? `${this.baseUrl}/${this.pageSlug}` : `${this.baseUrl}/en/${this.pageSlug}`
        },
      ]
    }
    if (this.$route.path && this.$route.path.length === 0) return false
    return obj
  },
  mounted() {
    if(this.$refs.singlePost) {
      if(this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.title) {
        this.postTitle = this.$refs.singlePost.pageData.seo_title
      }
      if(this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.media && this.$refs.singlePost.pageData.media.newPath) {
        this.ogImage = this.$refs.singlePost.pageData.media.newPath
      }
      if(this.$refs.singlePost.pageData && this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.slug) {
        this.pageSlug = this.$refs.singlePost.pageData.slug
      }
    }
    if(window) {
      this.baseUrl = window.location.origin;
    }
  }
}
