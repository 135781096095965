import { render, staticRenderFns } from "./ErrorView.vue?vue&type=template&id=3947ee9f&scoped=true"
import script from "./ErrorView.vue?vue&type=script&lang=js"
export * from "./ErrorView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3947ee9f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerError: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerError.js').default})
